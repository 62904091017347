//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
//import { getEntityTableColumns } from "@/utils/enums";
//import { dateRangeDays } from "@/utils/dates";

export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    routeParams: {
      type: Object,
      required: true
    }
  },
  // created() {
  //     this.reportSettings.mp = this.routeParams.mp
  // },

  methods: {
    refresh() {
      this.$refs.table.refresh();
    },
    tableLoader({
      query
    }) {
      return this.$store.dispatch("list/getListProducts", {
        ...query,
        ...this.reportSettings
      });
    }
  },
  computed: {
    columns() {
      console.log('this.reportSettings=', this.reportSettings);
      //console.log('this.routeParams=', this.routeParams)

      /*
      return [
          {
              title: "产品",
              position: 1,
              show: true,
              name: "title",
              width: 380,
              filter: "text",
              type: "link",
              noOverflow: true,
              getImage: (item) => item.image,
              getLink: (item) => {
                  return {
                      route: {
                          name: "ProductInfo",
                          params: {
                              ...this.routeParams,
                              p_id: item.p_id,
                          },
                          query: {
                              date: this.reportSettings.date,
                              date2: this.reportSettings.date2,
                              fbs: this.reportSettings.fbs,
                          },
                      },
                      text: item.title,
                      target: "_blank",
                  };
              },
          },
          {
              title: "SKU",
              position: 2,
              show: true,
              name: "sku",
              width: 100,
              filter: "text",
              type: "link",
              getLink: (item) => {
                  return {
                      href: item.mp_url,
                      text: item.sku,
                  };
              },
          },
           ...getEntityTableColumns("product", 3),
          {
              title: "第一的",
              show: true,
              name: "date",
              width: 100,
              filter: "dateRange",
              type: "date",
          },
          {
              title: "上次",
              show: true,
              name: "date2",
              width: 110,
              filter: "dateRange",
              type: "date",
          },
      ];
      */

      // = /src/components/Category/ProductsTable.vue
      /*
      const days = dateRangeDays(
          this.reportSettings.date,
          this.reportSettings.date2
      );
      */

      let a = [{
        title: "产品",
        show: true,
        name: "title",
        width: 380,
        filter: "text",
        type: "link",
        noOverflow: true,
        getImage: item => item.image,
        getLink: item => {
          return {
            route: {
              name: "ProductInfo",
              params: {
                ...this.routeParams,
                p_id: item.p_id
              },
              query: {
                date: this.reportSettings.date,
                date2: this.reportSettings.date2,
                fbs: this.reportSettings.fbs
              }
            },
            text: item.title,
            target: "_blank"
          };
        }
      }, {
        title: "SKU",
        show: true,
        name: "sku",
        width: 100,
        filter: "text",
        type: "link",
        getLink: item => {
          return {
            href: item.mp_url,
            text: item.sku
          };
        }
      }, {
        //title: `已售 ${days} 天(件)`,
        title: `销量(件)`,
        show: true,
        name: "sold",
        type: "number",
        width: 180,
        filter: "numberRange"
      }, {
        //title: `${days} 天的收入`,
        title: `营收`,
        show: true,
        name: "revenue",
        type: "money",
        width: 180,
        filter: "numberRange"
      }];
      if (this.routeParams.mp == "wb") {
        a = a.concat([{
          //title: "价格",
          title: "带WB钱包的价格",
          //wb-no-spp
          show: true,
          name: "price",
          type: "money",
          width: 100,
          filter: "numberRange"
        },
        /*
        {
            title: "SPP",
            show: true,
            name: "price_discount_spp",
            type: "percent",
            width: 100,
            filter: "numberRange",
        },
        */
        {
          //title: "基本价格",
          title: "价格",
          //wb-no-spp
          show: true,
          name: "price_spp",
          type: "money",
          width: 100,
          filter: "numberRange"
        }, {
          title: "折扣",
          show: true,
          name: "discount_wb",
          type: "percent",
          width: 100,
          filter: "numberRange"
        }, {
          title: "旧价格",
          show: true,
          name: "price_old",
          type: "money",
          width: 100,
          filter: "numberRange"
        }]);
      } else {
        a = a.concat([{
          title: "价格",
          show: true,
          name: "price",
          type: "money",
          width: 100,
          filter: "numberRange"
        }, {
          title: "旧价格",
          show: true,
          name: "price_old",
          type: "money",
          width: 100,
          filter: "numberRange"
        }, {
          title: "折扣",
          show: true,
          name: "discount",
          type: "percent",
          width: 100,
          filter: "numberRange"
        }]);
      }

      /*
      {
          title: "类别",
          show: true,
          name: "category",
          width: 380,
          filter: "select",
          type: "slot",
      },
      {
          title: "类别列表排名",
          show: true,
          name: "position",
          type: "number",
          width: 100,
          filter: "numberRange",
      },
      */

      a = a.concat([
      /*
      {
          title: `${days} 天的评论`,
          show: true,
          name: "new_reviews",
          type: "number",
          width: 100,
          filter: "numberRange",
      },
      */
      {
        //title: "当前余额(个)",
        title: "当前余额",
        show: true,
        name: "remains",
        type: "number",
        width: 120,
        filter: "numberRange"
      }, {
        title: "评论",
        show: true,
        name: "p_reviews",
        type: "number",
        width: 100,
        filter: "numberRange"
      }, {
        title: "评分",
        show: true,
        name: "rating",
        type: "number",
        width: 100,
        filter: "numberRange"
      }, {
        title: "类别数",
        show: true,
        name: "categories",
        type: "number",
        width: 100,
        filter: "numberRange"
      }, {
        //title: "关键词数量",
        title: "搜索查询",
        show: true,
        name: "keywords",
        width: 100,
        filter: "numberRange",
        type: "link",
        noOverflow: true,
        getLink: item => {
          return {
            route: {
              name: "ProductInfo",
              params: {
                ...this.routeParams,
                p_id: item.p_id,
                tab: "keywordsPositions"
              },
              query: {
                date: this.reportSettings.date,
                date2: this.reportSettings.date2,
                fbs: this.reportSettings.fbs
              }
            },
            text: item.keywords,
            target: "_blank"
          };
        }
      }, {
        title: `平均. 日销量(个)`,
        show: true,
        name: "avg_sold",
        type: "number",
        width: 180,
        filter: "numberRange"
      }, {
        title: `平均. 每日收入`,
        show: true,
        name: "avg_revenue",
        type: "money",
        width: 180,
        filter: "numberRange"
      }, {
        title: `库存天数`,
        show: true,
        name: "days",
        type: "number",
        width: 180,
        filter: "numberRange"
      }, {
        title: `收入损失`,
        show: true,
        name: "losses",
        type: "money",
        width: 180,
        filter: "numberRange"
      }, {
        title: "第一的",
        show: true,
        name: "date",
        width: 100,
        filter: "dateRange",
        type: "date"
      }, {
        title: "上次",
        show: true,
        name: "date2",
        width: 110,
        filter: "dateRange",
        type: "date"
      }]);

      /*
      {
          title: "品牌",
          show: true,
          name: "brand",
          width: 100,
          filter: "select",
          type: "link",
          getLink: (item) => {
              return {
                  route: {
                      name: "BrandInfo",
                      params: {
                          b_id: item.b_id,
                      },
                      query: {
                          date: this.reportSettings.date,
                          date2: this.reportSettings.date2,
                          fbs: this.reportSettings.fbs,
                      },
                  },
                  text: item.brand,
                  target: "_blank",
              };
          },
      },
      ];
      */

      /*
      a = a.concat([
      {title: "重量, 克",
          name: "p_weight",
          type: "number",
          width: 100,
          filter: "numberRange",
          show: true,
      }])
      if( this.reportSettings.mp == "ozon" ){
          a = a.concat([
          {title: "卖家价格",
              name: "p_true_seller_price",
              type: "money",
              width: 100,
              filter: "numberRange",
              show: true,
          },
          {title: "赎回百分比, %",
              name: "p_delivered_percent",
              type: "percent",
              width: 100,
              filter: "numberRange",
              show: true,
          }])
      }
      */

      /*
      {
          title: "仓库",
          show: true,
          name: "warehouse",
          width: 120,
          filter: "select",
      },
      */
      /*
      if (this.reportSettings.mp == "wb")
          a = a.concat([
              {
                  title: "图片数量",
                  show: true,
                  name: "tot_photo",
                  type: "number",
                  width: 100,
                  filter: "numberRange",
              },
          ]);
      */

      /*
      a = a.concat([
          {
              title: "推销员",
              show: true,
              name: "seller",
              width: 200,
              filter: "select",
              type: "link",
              getLink: (item) => {
                  return {
                      route: {
                          name: "SellerInfo",
                          params: {
                              s_id: item.s_id,
                          },
                          query: {
                              date: this.reportSettings.date,
                              date2: this.reportSettings.date2,
                              fbs: this.reportSettings.fbs,
                          },
                      },
                      text: item.seller,
                      target: "_blank",
                  };
              },
          },
      ]);
      */

      return a.map((item, i) => {
        item.position = i + 1;
        return item;
      });
    }
  }
};