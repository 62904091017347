//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ReportMixin from "@/mixins/Report";
import RangeCalendar from "@/components/RangeCalendar.vue";
import ModalListImport from "@/components/modals/ModalListImport.vue";
import ModalListRemoveItem from "@/components/modals/ModalListRemoveItem.vue";
import DataTableActions from "@/components/DataTable/DataTableActions.vue";
import MainTitle from "@/components/MainTitle.vue";
import CategoriesTable from "@/components/List/CategoriesTable.vue";
import ProductsTable from "@/components/List/ProductsTable.vue";
import BrandsTable from "@/components/List/BrandsTable.vue";
import SellersTable from "@/components/List/SellersTable.vue";
import SwitchInput from "@/components/SwitchInput.vue";
const tableComponents = {
  category: CategoriesTable,
  product: ProductsTable,
  brand: BrandsTable,
  seller: SellersTable
};
export default {
  mixins: [ReportMixin],
  meta() {
    var _this$list;
    const name = (_this$list = this.list) === null || _this$list === void 0 ? void 0 : _this$list.title;
    return {
      title: name ? `列出 "${name}"` : undefined
    };
  },
  data() {
    return {
      list: null,
      showModalEditLists: false
    };
  },
  mounted() {
    this.loadList();
  },
  methods: {
    // override mixin marketplace getter
    getMP() {
      var _this$list2;
      return (_this$list2 = this.list) === null || _this$list2 === void 0 ? void 0 : _this$list2.mp;
    },
    getTableId() {
      return this._uid;
    },
    async loadList() {
      this.list = await this.$store.dispatch("list/getList", {
        id: this.$route.params.id
      });
    },
    getTableComponent() {
      var _this$list3;
      return tableComponents[(_this$list3 = this.list) === null || _this$list3 === void 0 ? void 0 : _this$list3.entity];
    },
    deselect() {
      this.table_data.data.forEach(el => {
        el.checked = false;
      });
      this.select_all = false;
    },
    deleteItem() {
      let data = [];
      this.table_data.data.forEach(item => {
        if (!item.checked) {
          data.push(item);
        }
      });
      this.table_data.data = [...data];
    },
    onImportClick() {
      this.$refs.modalListImport.open({
        item: this.list
      });
    },
    onImportComplete() {
      this.$refs.table.refresh();
    },
    onRemove({
      items,
      clean,
      refresh
    }) {
      this.$refs.modalListRemoveItem.open({
        list: this.list,
        items,
        onComplete() {
          clean();
          refresh();
        }
      });
    }
  },
  computed: {
    fileName() {
      return `${this.routeParams.mp.toUpperCase()} 列表 ${this.list.title} ${this.reportSettings.date} ${this.reportSettings.date2}`;
    },
    selectedItems() {
      if (this.table_data && this.table_data.data) {
        let res = [];
        this.table_data.data.forEach(el => {
          if (el.checked) res.push(el);
        });
        return res;
      } else {
        return [];
      }
    },
    listKey() {
      return `${this.$route.params.id}`;
    },
    reportSettings() {
      if (!this.calendar || !this.list) {
        return null;
      }
      return {
        id: this.$route.params.id,
        date: this.calendar[0],
        date2: this.calendar[1],
        fbs: this.fbs
      };
    },
    routeParams() {
      var _this$list4;
      return {
        mp: (_this$list4 = this.list) === null || _this$list4 === void 0 ? void 0 : _this$list4.mp
      };
    }
  },
  watch: {
    listKey() {
      this.loadList();
    }
  },
  components: {
    RangeCalendar,
    ModalListImport,
    DataTableActions,
    MainTitle,
    ModalListRemoveItem,
    SwitchInput
  }
};